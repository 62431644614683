exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chirurgia-js": () => import("./../../../src/pages/chirurgia.js" /* webpackChunkName: "component---src-pages-chirurgia-js" */),
  "component---src-pages-choroby-krolikow-gryzoni-js": () => import("./../../../src/pages/choroby-krolikow-gryzoni.js" /* webpackChunkName: "component---src-pages-choroby-krolikow-gryzoni-js" */),
  "component---src-pages-dermatologia-js": () => import("./../../../src/pages/dermatologia.js" /* webpackChunkName: "component---src-pages-dermatologia-js" */),
  "component---src-pages-dietetyka-js": () => import("./../../../src/pages/dietetyka.js" /* webpackChunkName: "component---src-pages-dietetyka-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interna-js": () => import("./../../../src/pages/interna.js" /* webpackChunkName: "component---src-pages-interna-js" */),
  "component---src-pages-kardiologia-js": () => import("./../../../src/pages/kardiologia.js" /* webpackChunkName: "component---src-pages-kardiologia-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-laboratorium-js": () => import("./../../../src/pages/laboratorium.js" /* webpackChunkName: "component---src-pages-laboratorium-js" */),
  "component---src-pages-paszporty-i-czipowanie-js": () => import("./../../../src/pages/paszporty-i-czipowanie.js" /* webpackChunkName: "component---src-pages-paszporty-i-czipowanie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-poloznictwo-i-rozrod-js": () => import("./../../../src/pages/poloznictwo-i-rozrod.js" /* webpackChunkName: "component---src-pages-poloznictwo-i-rozrod-js" */),
  "component---src-pages-profilaktyka-js": () => import("./../../../src/pages/profilaktyka.js" /* webpackChunkName: "component---src-pages-profilaktyka-js" */),
  "component---src-pages-radiologia-js": () => import("./../../../src/pages/radiologia.js" /* webpackChunkName: "component---src-pages-radiologia-js" */),
  "component---src-pages-stomatologia-js": () => import("./../../../src/pages/stomatologia.js" /* webpackChunkName: "component---src-pages-stomatologia-js" */),
  "component---src-pages-usg-js": () => import("./../../../src/pages/usg.js" /* webpackChunkName: "component---src-pages-usg-js" */)
}

